import { aglutApi } from '@/services/APIs';
import { ShowAlertError } from '@/utils/alerts_error';
import { getCookie, setCookie } from '@/utils/cookies';
import { decodeAES } from '@/utils/helpers';
import { IDataCardMarketplace, IDataInfoUserCookie, IIndicador, IObjTeamToInfoUserLogged, IPermissionsUserAccess, IPropsActionPermissions } from '@/utils/types';
import { parseCookies } from 'nookies';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface IPropHandlePetmissions {
  setState: any;
  namePage: string[];
}

interface MainContextData {
  isOpenSidebar: boolean;
  loading: boolean;
  updateKpis: boolean;
  openOrCloseDropdown: boolean;
  infoUser: IDataInfoUserCookie;
  openModalCardKpi: boolean;
  dataToModal: IIndicador;
  dataToMarket: IDataCardMarketplace[];
  idsItemCartToMarketplace: number[];
  selectDataPlot: string;
  loadDataInfoUser: boolean;
  setInfoUser: (data: IDataInfoUserCookie) => void;
  setUpdateKpis: (value: boolean) => void;
  setLoading: (value: boolean) => void;
  showHideSidebar: () => void;
  setOpenOrCloseDropdown: (value: boolean) => void;
  handleGetPermissionsModules: (idSelected?: number) => void;
  setModalCardKpi: (value: boolean) => void;
  setDataToModal: (value: IIndicador) => void;
  addDataToCartMarketplace: (data: IDataCardMarketplace) => void;
  setSelectDataPlot: (id: string) => void;
  changedRouter: boolean;
  setChangedRouter: (value: boolean) => void;
  bindMarket: boolean;
  setBindMarket: (value: boolean) => void;
  setLoadDataInfoUser: (value: boolean) => void;
  hasPermission: (data: IPropsActionPermissions) => boolean;
  handlePermissions: (data: IPropHandlePetmissions) => void;
  setIdsItemCartToMarketplace: (value: number[]) => void;
  setSelectStep: (value: number) => void;
  selectStep: number;
}

const MainContext = createContext<MainContextData>({} as MainContextData);

const MainProvider = ({ children }: any) => {

  const [isOpenSidebar, setIsOpenSidebar] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [updateKpis, setUpdateKpis] = useState<boolean>(false);
  const [openOrCloseDropdown, setOpenOrCloseDropdown] = useState<boolean>(false);
  const [changedRouter, setChangedRouter] = useState<boolean>(false);
  const [infoUser, setInfoUser] = useState<IDataInfoUserCookie>({} as IDataInfoUserCookie);
  const [bindMarket, setBindMarket] = useState<boolean>(false)
  const [openModalCardKpi, setModalCardKpi] = useState(false);
  const [dataToModal, setDataToModal] = useState<IIndicador>({} as IIndicador);

  const [dataToMarket, setDataToMarket] = useState<IDataCardMarketplace[]>([]);
  const [idsItemCartToMarketplace, setIdsItemCartToMarketplace] = useState<number[]>([]);
  const [selectDataPlot, setSelectDataPlot] = useState<string>("");

  const [loadDataInfoUser, setLoadDataInfoUser] = useState<boolean>(false);

  const [selectStep, setSelectStep] = useState<number>(0);

  const showHideSidebar = () => {
    setIsOpenSidebar(!isOpenSidebar);
  }

  const handleGetPermissionsModules = useCallback(async (idSelected: number | null = null) => {
    setLoadDataInfoUser(true);
    const {'idTeam': idTeam} = parseCookies();
    const headers = { 'Content-Type': 'application/json' }
    await aglutApi.get(aglutApi.defaults.baseURL + '/team-members/user-info', {
      headers
    })
    .then(res => {
      if(res.data.teams.length > 0) {
        if(idSelected === null) {
          if(idTeam !== undefined) {
            const decodeID = decodeAES(idTeam);
            const equipe = res.data.teams.filter((team: any) => team.id === parseInt(decodeID))
            aglutApi.defaults.params['team_id'] = equipe.length > 0 ? decodeID : res.data.teams[0].id;
          } else {
            aglutApi.defaults.params['team_id'] = res?.data.teams[0].id;
          }
        } else {
          aglutApi.defaults.params['team_id'] = idSelected;
        }
      }
      const objModules: IObjTeamToInfoUserLogged = res.data.teams;
      localStorage.setItem('0bjw0du13s', JSON.stringify(objModules));
      
      res.data.teams.map((dt: any) => delete dt.modules)
      
      let teamSelected = res.data.teams[0]

      if(idSelected !== null) {
        teamSelected = res.data.teams.filter((team:any) => team.id === idSelected)[0]
      } else {
        if(idTeam !== undefined) {
          const decodeID = decodeAES(idTeam);
          const equipe = res.data.teams.filter((team:any) => team.id === parseInt(decodeID))
          teamSelected = equipe.length > 0 ? equipe[0] : res.data.teams[0];
        }
      }
      
      const objInfoUser: IDataInfoUserCookie = {
        has_team: res.data.has_team,
        teamSelected: teamSelected,
        teams: res.data.teams,
        user: res.data.user,
      }

      setInfoUser(objInfoUser);
      setLoadDataInfoUser(false);
    })
    .catch(error => {
      console.log(error);
      setLoadDataInfoUser(false);
      ShowAlertError(error);
      if(window.location.pathname !== '/') {
        window.location.href = '/';
      }
    })
  },[]);

  const addDataToCartMarketplace = (dado: IDataCardMarketplace) => {
    setIdsItemCartToMarketplace(prevState => [...prevState, dado.id]);
  };

  const handlePermissions = ({ namePage, setState }: IPropHandlePetmissions) => {
    if(infoUser.user) {
      const thisPerissions: IPermissionsUserAccess[] = infoUser.teamSelected ? infoUser.teamSelected?.system_modules?.filter(item => {
        // namePage.filter((name) => {
        //   if(item.code === name) return item
        // })

        if (Array.isArray(namePage)) {
          return namePage.includes(item.code)
        }
      }) : [];
      
      setState(thisPerissions);
      setLoadDataInfoUser(false);
    }
  }

  const hasPermission = (data: IPropsActionPermissions) => {
    if(data.permissions === undefined) {
      return false
    }

    const thisPerissions = data.permissions.filter(item => {
      if(item.code === data.model_name) {
        return item
      }
    })

    if(thisPerissions.length > 0) {
      return thisPerissions[0]?.permissions?.find((perm) => {
        if(data.model_name) {
          return (perm.action === data.action) && perm.model_name === data.model_name
        }
        return perm.action === data.action
      }) ? true : false;
    } else {
      const thisPermissionsModule = data.permissions.filter(item => {
        const permissions = item.permissions.filter(perm => {
          if(perm.model_name === data.model_name) {
            return perm
          }
        })
        if(permissions.length > 0) {
          return item
        }
      })
      return thisPermissionsModule[0]?.permissions?.find((perm) => {
        if(data.model_name) {
          return (perm.action === data.action) && perm.model_name === data.model_name
        }
        return perm.action === data.action
      }) ? true : false;
    }
  }

  const cookie = getCookie('firstSteps');
  
  if(!cookie) {
    const firstStepsModules = {
      kpis: false,
      okrs: false,
      teams: false,
      sector: false,
      dataMarker: false,
      settings: false,
      event: false,
      dataAnalytic: false
    }
    
    setCookie('firstSteps', JSON.stringify(firstStepsModules));
  }

  return (
    <MainContext.Provider
      value={{
        isOpenSidebar,
        loading,
        openOrCloseDropdown,
        infoUser,
        updateKpis,
        openModalCardKpi,
        dataToModal,
        setDataToModal,
        setModalCardKpi,
        dataToMarket,
        idsItemCartToMarketplace,
        selectDataPlot,
        setInfoUser,
        setLoading,
        showHideSidebar,
        setUpdateKpis,
        setOpenOrCloseDropdown,
        handleGetPermissionsModules,
        addDataToCartMarketplace,
        setSelectDataPlot,
        changedRouter,
        setChangedRouter,
        bindMarket,
        setBindMarket,
        loadDataInfoUser,
        setLoadDataInfoUser,
        hasPermission,
        handlePermissions,
        setIdsItemCartToMarketplace,
        setSelectStep,
        selectStep
      }}
    >
      {children}
    </MainContext.Provider>
  )
}

function useMain(): MainContextData {
  const context = useContext(MainContext);

  if(!context) {
    throw new Error("useMain must be used within an MainProvider");
  }

  return context;
}

export { MainProvider, useMain }