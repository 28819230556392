import { useEffect } from "react";
import { getCookie, setCookie } from "@/utils/cookies";
import { parseCookies } from 'nookies'
import { LatLngExpression } from "leaflet";

var CryptoJS = require("crypto-js");

/**
 * Retorna um econdeBase64
 */
export const encodeBase64 = (data: string) => {
  return Buffer.from(data).toString('base64');
}

/**
 * Retorna a decodificação de base64
 */
export const decodeBase64 = (data: string) => {
  return Buffer.from(data, 'base64').toString('ascii');
}

/**
 * Retorna os dados do token
 */
export const parseJwt = (token: string) => {
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}

/**
 * Substitui limpando palavras com acentos
 */
export const clearString = (str: string) => {
  str = str.replace(/[ÀÁÂÃÄÅ]/g,"A");
  str = str.replace(/[àáâãäå]/g,"a");
  str = str.replace(/[ÈÉÊË]/g,"E");
  str = str.replace(/[ç]/ui, 'c');
  return str;
};


export const encodeAES = (str: string) => {
  const AES = CryptoJS.AES.encrypt(str, process.env.NEXT_PUBLIC_SECRET_PASSWORD_AES).toString();
  return AES;
}

export const decodeAES = (str: string) => {
  const bytes = CryptoJS.AES.decrypt(str, process.env.NEXT_PUBLIC_SECRET_PASSWORD_AES);
  let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
}

export const formatCurrency = (value: number) => {
  return new Intl.NumberFormat(
    'pt-br',
    { style: 'currency', currency: 'BRL' }
  ).format(value);
};

export const cleanCookie = () => {
  setCookie('kcToken', '', {expires: -1})
  setCookie('kcTokenR', '', {expires: -1});
  setCookie('1n3o', '', {expires: -1});
  setCookie('ojbn4tf', '', {expires: -1});
  setCookie('3xp1r3', '', {expires: -1});
  setCookie('idToken', '', {expires: -1});

  return
}

export const getIdToken = () => {
  const { 'idToken': idToken } = parseCookies();
  const decodeIdToken = idToken.includes("U2FsdGV") ? decodeAES(idToken) : idToken;
  return decodeIdToken
}

export const forceLogout = () => {
  const {origin} = window.location;
  let decodeIdToken = getIdToken()
  
  cleanCookie()
  window.location.href=`${process.env.NEXT_PUBLIC_KEYCLOAK_URL_LOGOUT}?client_id=${process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID}&post_logout_redirect_uri=${origin}/callback&id_token_hint=${decodeIdToken}`;
}

export const mockDataImport = [
  { id: 1, coluna01: 'Valor 01-1', coluna02: 'Valor 02-1', coluna03: 'Valor 03-1', coluna04: 'Valor 04-1', coluna05: 'Valor 05-1' },
  { id: 2, coluna01: 'Valor 01-2', coluna02: 'Valor 02-2', coluna03: 'Valor 03-2', coluna04: 'Valor 04-2', coluna05: 'Valor 05-2' },
  { id: 3, coluna01: 'Valor 01-3', coluna02: 'Valor 02-3', coluna03: 'Valor 03-3', coluna04: 'Valor 04-3', coluna05: 'Valor 05-3' },
  { id: 4, coluna01: 'Valor 01-4', coluna02: 'Valor 02-4', coluna03: 'Valor 03-4', coluna04: 'Valor 04-4', coluna05: 'Valor 05-4' },
  { id: 5, coluna01: 'Valor 01-5', coluna02: 'Valor 02-5', coluna03: 'Valor 03-5', coluna04: 'Valor 04-5', coluna05: 'Valor 05-5' },
  { id: 6, coluna01: 'Valor 01-6', coluna02: 'Valor 02-6', coluna03: 'Valor 03-6', coluna04: 'Valor 04-6', coluna05: 'Valor 05-6' },
  { id: 7, coluna01: 'Valor 01-7', coluna02: 'Valor 02-7', coluna03: 'Valor 03-7', coluna04: 'Valor 04-7', coluna05: 'Valor 05-7' },
  { id: 8, coluna01: 'Valor 01-8', coluna02: 'Valor 02-8', coluna03: 'Valor 03-8', coluna04: 'Valor 04-8', coluna05: 'Valor 05-8' },
  { id: 9, coluna01: 'Valor 01-9', coluna02: 'Valor 02-9', coluna03: 'Valor 03-9', coluna04: 'Valor 04-9', coluna05: 'Valor 05-9' },
  { id: 10, coluna01: 'Valor 01-10', coluna02: 'Valor 02-10', coluna03: 'Valor 03-10', coluna04: 'Valor 04-10', coluna05: 'Valor 05-10' },
  { id: 11, coluna01: 'Valor 01-11', coluna02: 'Valor 02-11', coluna03: 'Valor 03-11', coluna04: 'Valor 04-11', coluna05: 'Valor 05-11' },
  { id: 12, coluna01: 'Valor 01-12', coluna02: 'Valor 02-12', coluna03: 'Valor 03-12', coluna04: 'Valor 04-12', coluna05: 'Valor 05-12' },
  { id: 13, coluna01: 'Valor 01-13', coluna02: 'Valor 02-13', coluna03: 'Valor 03-13', coluna04: 'Valor 04-13', coluna05: 'Valor 05-13' },
  { id: 14, coluna01: 'Valor 01-14', coluna02: 'Valor 02-14', coluna03: 'Valor 03-14', coluna04: 'Valor 04-14', coluna05: 'Valor 05-14' },
  { id: 15, coluna01: 'Valor 01-15', coluna02: 'Valor 02-15', coluna03: 'Valor 03-15', coluna04: 'Valor 04-15', coluna05: 'Valor 05-15' },
];

export const converteData = (data: string, isHours: boolean) => {
  const dataLocale = new Date(data.slice(0, -1));

  const dia = dataLocale.getDate();
  const mes = dataLocale.getMonth() + 1;
  const ano = dataLocale.getFullYear();
  const hora = dataLocale.getHours();
  const minuto = dataLocale.getMinutes();
  const segundo = dataLocale.getSeconds();

  return handleZeroWhenRequired({ 
    day: dia, 
    month: mes, 
    year: ano, 
    hour: hora, 
    minute: minuto, 
    second: segundo, 
    isHours });
};

type FormartDate = {
  day: number;
  month: number;
  year: number;
  hour: number;
  minute: number;
  second: number;
  isHours: boolean;
}

function handleZeroWhenRequired({
  day, month, year, hour, minute, second, isHours
}: FormartDate) {
  const dayString = day.toString().padStart(2, '0');
  const monthString = month.toString().padStart(2, '0');
  const yearString = year.toString();
  const hourString = hour.toString().padStart(2, '0');
  const minuteString = minute.toString().padStart(2, '0');
  const secondString = second.toString().padStart(2, '0');

  if (isHours) {
    return `${dayString}/${monthString}/${yearString} ${hourString}:${minuteString}:${secondString}`;
  }

  return `${dayString}/${monthString}/${yearString}`;
}

export function invertAddFormatDate(date: string) {
  return date.split('-').reverse().join('/');
}


/**
 * Sets the first step for a given module in the cookie.
 *
 * @param {string} nameModule - The name of the module.
 * @return {void} This function does not return anything.
 */
export const handleSetFirstStep = (nameModule: string): void => {
  const cookie = getCookie('firstSteps');
  const parseCookie = JSON.parse(cookie || '{}');
  const updatedCookie = { ...parseCookie, [nameModule]: true };

  setCookie('firstSteps', JSON.stringify(updatedCookie));
}

interface ISetRequestComplete {
  nameRequest: (
    'homePriceCompare' | 'homePredictionClimate' | 'homeCountry' |
    'teamRequest' | 'dashKpisRequest' | 'dashOkrRequest' | 'dashSectorsRequest' |
    'markersRequest' | 'ocurrencesRequest' | 'kpiRequest' | 'kpiCategoriesRequest' |
    'okrsRequest' | 'okrsStatusRequest' | 'okrsUsersRequest' | 'analisesDataRepoRequest' |
    'analisesGeneralStatsReq' | 'analisesPredictReq' | 'analisesHistogramReq' | 
    'analisesDispersionReq' | 'analisesMatrizReq' | 'analisesDistFitReq' | 'configurationsRequest'
  )[];
  status?: 'success' | 'failed' ;
}

export const checkRequestComplete = {
    setRequest: ({ nameRequest, status = 'success' }: ISetRequestComplete) => {
      nameRequest.map(request => {
        localStorage.setItem(request, status);
      })
    },
    getRequest: ({ nameRequest }: ISetRequestComplete) => {
      const checkRequests = nameRequest.map(request => localStorage.getItem(request));
      return checkRequests;
    },
    clearRequestStorage: ({ nameRequest }: ISetRequestComplete) => {
      nameRequest.map(request => {
        localStorage.removeItem(request);
      })
    }
}

/**
 * Checks if an object has a specified property and if the property value is not null.
 *
 * @param {any} obj - The object to check.
 * @param {string} prop - The property to check for.
 * @return {boolean} Returns true if the object has the property and the property value is not null, otherwise returns false.
 */
export const hasProperty = (obj: any, prop: string) : boolean => {
  if (Object.prototype.hasOwnProperty.call(obj, prop)) {
    if(obj[prop] !== null && obj[prop] !== undefined) {
      return true
    }
    return false
  }
  return false
}


/**
   * Function to process data and return it in a different format.
   *
   * @param {string} data - the input data to be processed
   * @param {boolean} onlyData - flag to indicate if only the date part should be returned
   * @return {string} the processed data in the specified format
   */
export const trataData = (data: string, onlyData: boolean = false): string => {
  const separataData: string[] = data.split("T");
  if (onlyData) {
    const dataTratada: string[] = separataData[0].split("-");
    return `${dataTratada[2]}/${dataTratada[1]}/${dataTratada[0]}`;
  } else {
    const [year, mouth, day]: string[] = separataData[0].split("-");
    const [hour, minutes]: string[] = separataData[1].split(":");
    return `${day}/${mouth}/${year} - ${hour}:${minutes}`;
  }
};

type LatLng = {
  lat: number;
  lng: number;
}

/**
* Função para padronizar as informações realizadas a layer criada pelo geojson
* @param feature Informações da Layer
* @param dataNow Data da predição
* @returns Retorna um Elemento HTML
*/
export const createPopupPadronizado = (feature: any, dataNow: string, id: string) => {
  if (feature.hasOwnProperty("render_type")) {
    return `
      <div class='content-data-info'>
        <div class='header mb-3'>
          <h6 class='area-id'>${feature.name} - ${feature.sort_name}</h6>
        </div>
        <div class='body'>
          <div class='info mb-3'>
            <h5>Detalhamento</h5>
            <p>${feature.description}</p>
          </div>
          <div class='info mb-3'>
            <h5>Análise</h5>
            <div class='table-responsive'>
              <table class='content-table_${id} table table-striped table-bordered'>
                <thead>
                  <tr>
                    <th>Cultura</th>
                    <th>Ontem</th>
                    <th>${dataNow === '' ? 'Hoje' : trataData(dataNow, true)}</th>
                    <th>Amanhã</th>
                  </tr>
                </thead>
                <tbody></tbody>
              </table>
            </div>
          </div>
          <div class='info mb-3'>
            <h5>Descrição</h5>
            <p>${feature.description}</p>
          </div>
          <div class='font-data'>
            <span>Fonte: Ceasa</span>
          </div>
        </div>
      </div>
    `;
  } else if(feature.value !== '') {
    return `
      <div class='content-data-info'>
        <div class='header mb-3'>
          <h6 class='area-id'>${feature.name} - ${feature.sort_name} - ${dataNow === '' ? 'Hoje' : trataData(dataNow)}</h6>
        </div>
        <div class='body'>
          <div class='info mb-3'>
            <h5>Detalhamento</h5>
            <ul>
              <li>
                <p>Tipo: ${feature.type_value !== "" ? feature.type_value : "Não informado" }</p>
              </li>
              <li>
                <p>Valor: 
                  ${
                    feature.type_value !== "preço"
                      ? (typeof feature.value === "number"
                        ? feature.value.toFixed(2)
                        : feature.value[0].toFixed(2)) + " " + feature.unit
                      : feature.unit + " " +
                        (typeof feature.value === "number"
                          ? feature.value.toFixed(2)
                          : feature.value[0].toFixed(2))
                  }
                </p>
              </li>
            </ul>
          </div>
          <div class='info mb-3'>
            <h5>Descrição</h5>
            <p>${feature.description}</p>
          </div>
          <div class='font-data'>
            <span>Fonte: Ceasa</span>
          </div>
        </div>
      </div>
    `;
  } else {
    return `
      <div class='content-data-info'>
        <div class='header mb-3'>
          <h6 class='area-id'>${feature.name} - ${feature.sort_name}</h6>
        </div>
        <div class='body'>
          <div class='info mb-3'>
            <h5>Descrição</h5>
            <p>${feature.description}</p>
          </div>
        </div>
      </div>
    `;
  }
};

/**
 * Creates a standardized tooltip for the given feature and current data.
 *
 * @param {any} feature - the feature for the tooltip
 * @param {string} dataNow - the current date and time
 * @return {string} the HTML content of the tooltip
 */
export const createTooltipPadronizado = (feature : any, dataNow: string): string => {
  if(feature.sort_name === undefined) {
    return `
      <div class='content-tooltip-info'>
        <div class='header mb-1'>
          <div class='info'>
            <p class='area-id text-center fw-bold fs-5'>${feature.name}</p>
          </div>
        </div>
        <div class='body border-top'>
          <p class='text-center'>${feature.description}</p>
        </div>
      </div>
    `
  } else {
    return `
      <div class='content-tooltip-info'>
        <div class='header mb-1'>
          <div class='info'>
            <p class='area-id text-center fw-bold fs-5'>${feature.name} - ${feature.sort_name}</p>
            <p class='text-center fw-semibold fs-6' style="color: #555">${feature.type_value}</p>  
            <h6 class='text-center mb-3'>
              ${
                feature.type_value !== null && feature.type_value !== undefined 
                ? feature.type_value !== "preço"
                  ? (typeof feature.value === "number"
                    ? feature.value.toFixed(2)
                    : feature.value[0].toFixed(2)) + " " + feature.unit
                  : feature.unit + " " +
                    (typeof feature.value === "number"
                      ? feature.value.toFixed(2)
                      : feature.value[0].toFixed(2))
                : ''
              }
            </h6>
          </div>
        </div>
        <div class='body border-top'>
          <p class='text-center'>${dataNow === '' ? 'Sem dados' : trataData(dataNow)}</p>
        </div>
      </div>
    `
  }
}


/**
 * Formats the given size in bytes into a human-readable format.
 *
 * @param {number} bytes - the size in bytes to be formatted
 * @param {number} [decimals=2] - the number of decimal places to round to
 * @return {string} the formatted size with the appropriate unit (B, KB, MB, GB, TB, PB, EB, ZB, or YB)
 */
export function formatBytes(bytes : number, decimals: number = 2): string {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}