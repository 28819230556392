import axios from 'axios';

const aglutApi = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  params: { team_id: '' }
});

const predictionApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_PREDICTIONS_ENDPOINT,
  headers: {
      'Content-Type': 'application/json',
  },
});

const marketplaceApi = axios.create({
  //10.10.70.76:8050
  //192.168.1.190:8000
  // baseURL: 'http://192.168.1.190:8003/api/v1/',
  //baseURL: 'http://192.168.13.30:8003/api/v1/',
  baseURL: process.env.NEXT_PUBLIC_BACKEND_MARKETPLACE_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
  },
});

const samanauApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_SAMANAU_ENDPOINT,
  headers: {
      'Content-Type': 'application/json',
  },
});

/**
 * API Publica
 */
const marketPublicApi = axios.create({
  //baseURL: 'http://10.10.70.76:31431/api/v1/public/',
  //baseURL: 'http://192.168.13.30:8003/api/v1/public/',
  baseURL: process.env.NEXT_PUBLIC_BACKEND_MARKETPLACE_ENDPOINT + '/public/',
  headers: {
      'Content-Type': 'application/json',
  },
});

const priceClimateDataApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_PRICE_CLIMATE_DATA_ENDPOINT,
  headers: {
      'Content-Type': 'application/json',
  },
})

export {aglutApi, predictionApi, marketplaceApi, samanauApi, marketPublicApi, priceClimateDataApi};
