import { toast } from "react-toastify";
import { setCookie } from "./cookies";

/**
 * Função para retornar o Alert do erro ocorrido no sistema.
 * @param error Error
 */
export function ShowAlertError(error: any) {
  const {origin} = window.location  
  console.log(error);
    if(!error.hasOwnProperty('response')) {
        toast.error(
            `
                Erro de conexão com o servidor, por favor, contactar o suporte.
            `,
            {
                autoClose: false,
            }
        );
    } else {
        console.log(error.response);
        switch (error.response.status) {
            case 400:
                toast.error(
                    `
                        Erro ${error.response.status}: Inconsistências no formulário - ${error.response.data.response === undefined ? error.response.data.detail : error.response.data.response}
                    `,
                    {
                        autoClose: 10000,
                    }
                );
                break;
            case 403:
                toast.error(
                    `
                        Erro ${error.response.status}: Você não possuí autorização para acessar a área solicitada.
                    `,
                    {
                        autoClose: 10000,
                    }
                );
                break;
            case 401:
                toast.error(
                    `
                        Erro ${error.response.status}: Sua sessão foi encerrada. Por favor, aguarde enquanto reativamos a sua sessão.
                    `,
                    {
                        autoClose: 10000,
                    }
                );
                setTimeout(()=>{
                  setCookie('kcToken', '', {expires: -1})
                  setCookie('kcTokenR', '', {expires: -1});
                  setCookie('1n3o', '', {expires: -1});
                  setCookie('ojbn4tf', '', {expires: -1});
                  setCookie('3xp1r3', '', {expires: -1});
                  window.location.href=`${process.env.NEXT_PUBLIC_KEYCLOAK_ISSUE}${process.env.NEXT_PUBLIC_KEYCLOAK_AUTH}${origin}/callback`;
                },1000)
                break;
            case 500:
                toast.error(
                    `
                        Erro ${error.response.status}: Algo ocorreu no servidor, por favor, contate o suporte.
                    `,
                    {
                        autoClose: 10000,
                    }
                );
                break;
            default:
                toast.error(
                    `
                        Erro ${error.response.status}: Algum problema inesperado ocorreu, por favor, contate o suporte.
                    `,
                    {
                        autoClose: 10000,
                    }
                );
                break;
        }
    }
}